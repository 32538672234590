import React from 'react'
import MegaMenuNav from './megamenu'
import MobileNav from './mobilemenu'
import backgroundImage from '../../static/images/bg-pattern.webp';

function Header() {
  return (
    <header className="lg:fixed top-0 z-20 w-full bg-repeat" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <MegaMenuNav className="hidden lg:block" />
        <MobileNav />
    </header>
  )
}

export default Header
