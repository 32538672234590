import React from "react";
import Header from "./header";
import Footer from "./footer";
import SideNav from "./sidebar"
import { Carousel } from "flowbite-react";
import { StaticImage } from "gatsby-plugin-image";
import BackToTop from "./backtotop";

const Layout = ({ children, location }) => {
  const isHomePage = location.pathname === "/";

  let pageTitle;
  let pageImage;

  const imageMapping = {
    "/our-hosts/": "/images/about-banner.webp",
    "/registration/": "/images/registration-banner.webp",
    "/accommodation/": "/images/accommodation-banner.webp",
    "/our-venue-and-melbourne/": "/images/venue-melbourne-banner.webp",
    "/visit-victoria/": "/images/visit-victoria-banner.webp",
    "/know-before-you-go/": "/images/visit-victoria-banner.webp",
    "/program-overview/": "/images/program-banner.webp",
    "/full-program/": "/images/program-banner.webp",
    "/our-presenters/": "/images/speakers-banner.webp",
    "/visa/": "/images/visa-banner.webp",
    "/contact/": "/images/contact-banner.webp",
    "/latest-news/": "/images/latest-news-banner.webp",
    "default": "/images/visit-victoria-banner.webp"
  };

  switch(location.pathname) {
    case "/our-hosts/":
      pageTitle = "Our Hosts";
      break;
    case "/registration/":
      pageTitle = "Registration";
      break;
    case "/accommodation/":
      pageTitle = "Accommodation";
      break;
    case "/our-venue-and-melbourne/":
      pageTitle = "Our Venue and Melbourne";
      break;
    case "/visit-victoria/":
      pageTitle = "Visit Victoria";
      break;
    case "/know-before-you-go/":
      pageTitle = "Know Before You Go";
      break;
    case "/visa/":
      pageTitle = "Getting a visa";
      break;
    case "/full-program/":
      pageTitle = "Program";
      break;
    case "/program-overview/":
      pageTitle = "Program Overview";
      break;
    case "/our-presenters/":
      pageTitle = "Our Presenters";
      break;
    case "/latest-news/":
      pageTitle = "Latest News";
      break;
    case "/contact/":
      pageTitle = "Contact Us";
      break;

    default:
      pageTitle = "Page Title";
  }

  pageImage = imageMapping[location.pathname] || imageMapping["default"];

  const customPages = ["/", "/full-program/"];
  const fullWidthPages = ["/full-program/"];
  const noSidebar = customPages.includes(location.pathname);
  const noSidebarFullWidth = fullWidthPages.includes(location.pathname);

  return (
    <div>
      <Header />
      {isHomePage ? (
      <section>
        <div className="h-[300px] md:h-[400px] lg:h-[500px] xl:h-[700px] xl:max-w-[2560px] mx-auto overflow-y-hidden lg:top-[188px] relative">
          <Carousel slideInterval={5000} indicators={false} pauseOnHover className="carousel">
            <StaticImage
              src="../images/iclr-home-banner.jpg"
              loading="lazy"
              layout="fullWidth"
              quality="75"
              formats={["avif","webp","auto"]}
              className="mx-auto object-cover h-[300px] md:h-[400px] lg:h-[500px] xl:h-[700px] object-right"
              alt="ICLR + CORO Home Banner"
            />
            <StaticImage
              src="../images/iclr-home-banner3.jpg"
              loading="lazy"
              layout="fullWidth"
              quality="75"
              formats={["avif","webp","auto"]}
              className="mx-auto object-cover h-[300px] md:h-[400px] lg:h-[500px] xl:h-[700px] object-right"
              alt="ICLR + CORO Home Banner"
            />
            <StaticImage
              src="../images/iclr-home-banner4.jpg"
              loading="lazy"
              layout="fullWidth"
              quality="75"
              formats={["avif","webp","auto"]}
              className="mx-auto object-cover h-[300px] md:h-[400px] lg:h-[500px] xl:h-[700px] object-right"
              alt="ICLR + CORO Home Banner"
            />
          </Carousel>
        </div>
      </section>
      ) : (
        <section>
          <div className="h-[150px] lg:h-[200px] xl:h-[300px] xl:max-w-[2560px] mx-auto overflow-y-hidden lg:top-[188px] relative">
            <div className="container mx-auto absolute flex items-center justify-center lg:justify-start inset-0 h-[150px] lg:h-[200px] xl:h-[300px] z-10">
              <div className="flex flex-col text-center bg-white/40 backdrop-blur-sm p-6">
                <h1 className="text-2xl lg:text-4xl xl:text-5xl font-bold uppercase poppins-bold">{pageTitle}</h1>
              </div>
            </div>
            <img
              src={pageImage}
              className="mx-auto object-cover h-[150px] lg:h-[200px] xl:h-[300px] w-full"
              alt={`${pageTitle} Page Banner`}
            />
          </div>
      </section>
      )}
      <main className={`${noSidebarFullWidth ? "lg:mt-44 px-4" : "container mx-auto lg:mt-44 px-4 xl:px-0"}`}>
      {noSidebar ? (
          <div className="py-10 xl:py-20">
            <div className="flex flex-col gap-16">{children}</div>
          </div>
        ) : (
          <div className="grid grid-cols-12 py-10 xl:py-20 gap-6">
            <div className="col-span-12 xl:col-span-9 flex flex-col gap-16 relative">{children}</div>
            <div className="col-span-12 xl:col-span-3"><SideNav /></div>
          </div>
        )}
      </main>
      <BackToTop />
      <Footer />
    </div>
  );
}

export default Layout;
