import React, { useState, useEffect, useRef } from 'react';
import { MegaMenu, Navbar } from 'flowbite-react';
import { HiChevronDown } from 'react-icons/hi';
import { Link } from 'gatsby';

const aboutMenuItems = [
    { name: 'Our Hosts', url: '/our-hosts', description: 'Learn about the hosts of ICLR + CORO' },
];

const registrationMenuItems = [
    { name: 'Registration', url: '/registration', description: 'Registration is open now' },
    { name: 'Accommodation', url: '/accommodation', description: 'Check availability of our recommended hotels' },
    { name: 'Getting a Visa', url: '/visa', description: 'Requirements for applying for a visa' },
];

const programMenuItems = [
    { name: 'Program', url: '/full-program', description: 'Check out the overview of the program' },
    { name: 'Our Presenters', url: '/our-presenters', description: 'Learn about the presenters of the program' },
];

const destinationMenuItems = [
    { name: 'Our Venue and Melbourne', url: '/our-venue-and-melbourne', description: 'Learn about our venue and Melbourne' },
    { name: 'Visit Victoria', url: '/visit-victoria', description: 'Tour locations in Victoria' },
    { name: 'Know Before You Go', url: '/know-before-you-go', description: 'Guide to the places in Melbourne' },
];

const MenuItem = ({ name, url, description, onClick }) => (
    <Link to={url} className="block rounded-lg p-3 hover:bg-gray-50" onClick={onClick}>
        <div className="font-semibold">{name}</div>
        <span className="text-sm text-gray-500">{description}</span>
    </Link>
);

function MegaMenuNav({ className }) {
    const [activeMenu, setActiveMenu] = useState(null);
    const dropdownRef = useRef(null);

    const dropdownStyle = "absolute w-full bottom-[-130px]";

    const toggleMenu = (menuName) => {
        setActiveMenu(activeMenu === menuName ? null : menuName);
    };

    const closeMenu = () => {
        setActiveMenu(null);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                closeMenu();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <MegaMenu className="!p-0 bg-transparent">
            <div className="flex justify-between items-center container mx-auto px-4 lg:px-0 py-4 text-white">
                <Link to="/">
                    <img
                        src="/images/ICLR-CORO2024-logo-REV.svg"
                        width={400}
                        loading="eager"
                        placeholder="blurred"
                        alt="ICLR CORO2024 logo reversed colour"
                        quality={100}
                    />
                </Link>
                <Navbar.Collapse className={`!hidden lg:!block navpul ${className}`}>
                    <Link to="/" className="text-white text-lg hover:!text-orange-400">Home</Link>
                    <Navbar.Link onClick={() => toggleMenu('about')}>
                        <span className="flex items-center cursor-pointer text-white text-lg hover:!text-orange-400">About <HiChevronDown className="ml-2" /></span>
                    </Navbar.Link>
                    <Navbar.Link onClick={() => toggleMenu('registration')}>
                        <span className="flex items-center cursor-pointer text-white text-lg hover:!text-orange-400">Registration <HiChevronDown className="ml-2" /></span>
                    </Navbar.Link>
                    <Navbar.Link onClick={() => toggleMenu('program')}>
                        <span className="flex items-center cursor-pointer text-white text-lg hover:!text-orange-400">Program <HiChevronDown className="ml-2" /></span>
                    </Navbar.Link>
                    <Navbar.Link onClick={() => toggleMenu('destination')}>
                        <span className="flex items-center cursor-pointer text-white text-lg hover:!text-orange-400">Destination <HiChevronDown className="ml-2" /></span>
                    </Navbar.Link>
                    <Link to="/latest-news" className="text-white text-lg hover:!text-orange-400">Latest news</Link>
                    <Link to="/contact" className="text-white text-lg hover:!text-orange-400">Contact us</Link>
                </Navbar.Collapse>
            </div>
            {activeMenu === 'about' && (
                <div ref={dropdownRef} className={dropdownStyle}>
                    <MegaMenu.Dropdown className="bg-white w-full border-gray-200 border-y">
                    <ul className="mx-auto grid max-w-screen-xl px-4 py-5 sm:grid-cols-2 md:grid-cols-3 md:px-6 mb-0">
                        {aboutMenuItems.map((item, index) => (
                        <li key={index}>
                            <MenuItem name={item.name} url={item.url} description={item.description} onClick={closeMenu} />
                        </li>
                        ))}
                    </ul>
                    </MegaMenu.Dropdown>
                </div>
            )}
            {activeMenu === 'registration' && (
                <div ref={dropdownRef} className={dropdownStyle}>
                    <MegaMenu.Dropdown className="bg-white w-full border-gray-200 border-y">
                        <ul className="mx-auto grid max-w-screen-xl px-4 py-5 sm:grid-cols-2 md:grid-cols-3 md:px-6 mb-0">
                            {registrationMenuItems.map((item, index) => (
                                <li key={index}>
                                    <MenuItem name={item.name} url={item.url} description={item.description} onClick={closeMenu} />
                                </li>
                            ))}
                        </ul>
                    </MegaMenu.Dropdown>
                </div>
            )}
            {activeMenu === 'program' && (
                <div ref={dropdownRef} className={dropdownStyle}>
                    <MegaMenu.Dropdown className="bg-white w-full border-gray-200 border-y">
                        <ul className="mx-auto grid max-w-screen-xl px-4 py-5 sm:grid-cols-2 md:grid-cols-3 md:px-6 mb-0">
                            {programMenuItems.map((item, index) => (
                                <li key={index}>
                                    <MenuItem name={item.name} url={item.url} description={item.description} onClick={closeMenu} />
                                </li>
                            ))}
                        </ul>
                    </MegaMenu.Dropdown>
                </div>
            )}
            {activeMenu === 'destination' && (
                <div ref={dropdownRef} className={dropdownStyle}>
                    <MegaMenu.Dropdown className="bg-white w-full border-gray-200 border-y">
                        <ul className="mx-auto grid max-w-screen-xl px-4 py-5 sm:grid-cols-2 md:grid-cols-3 md:px-6 mb-0">
                            {destinationMenuItems.map((item, index) => (
                                <li key={index}>
                                    <MenuItem name={item.name} url={item.url} description={item.description} onClick={closeMenu} />
                                </li>
                            ))}
                        </ul>
                    </MegaMenu.Dropdown>
                </div>
            )}
        </MegaMenu>
    );
}

export default MegaMenuNav;
